import React from 'react';
import { Menu } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../css/Footer.css';
import { useAuth0 } from "@auth0/auth0-react";
import {ReactComponent as FooterIllustration} from '../../web-footer-illustration.svg';
import {ReactComponent as FacebookLogo} from '../../facebook-logo.svg';
import {ReactComponent as InstagramLogo} from '../../instagram-logo.svg';
import {ReactComponent as YoutubeLogo} from '../../youtube-logo.svg';
import {ReactComponent as LinkedInLogo} from '../../linkedin-logo.svg';
import visaLogo from '../../visa-logo.png';
import mastercardLogo from '../../mastercard-logo.png';
import paypalLogo from '../../paypal-logo.png';
import {
    privacyPolicyURL,
    termsPolicyURL, 
    deliveryPolicyURL,
    refundPolicyURL,
    instagramURL,
    facebookURL,
    youtubeURL,
    linkedInURL,
    } from '../../configs/ExternalUrls.js';



// Login item for footer.
const LoginItem = () => {
    const { loginWithRedirect } = useAuth0();
    return (
      <div onClick={() => loginWithRedirect()} className='footerLink'>
        LOGIN
      </div>
    );
  };

// Logout item for footer.
const LogoutItem = () => {
    const { logout } = useAuth0();
    return (
        <div onClick={() => logout( { returnTo: window.location.origin } )} className='footerLink'>
            LOGOUT
        </div>
    );
}

// Auth item for footer.
const AuthItem = () => {
    const { isAuthenticated } = useAuth0();
    
    console.log(isAuthenticated);
    return isAuthenticated ? <LogoutItem /> : <LoginItem />;
};

// Admin tools available when authenticated.
const FooterWithAuth = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <Container className='footer' fluid>
            <Row>
                <Col className='footerLeft'>
                    <Row>
                        <Menu mode="vertical" className='footerMenu'>

                            <Menu.Item className='footerItem'>
                                <Link to='/' className='footerLink'>
                                    HOME
                                </Link>
                            </Menu.Item>

                            <Menu.Item className='footerItem'>
                                <Link to='/our_story' className='footerLink'> 
                                    OUR STORY
                                </Link>
                            </Menu.Item>

                            <Menu.Item className='footerItem'>
                                <Link to='/collections' className='footerLink'>
                                    COLLECTION
                                </Link>
                            </Menu.Item>

                            <Menu.Item className='footerItem'>
                                <Link to='/illustrations' className='footerLink'>
                                    GALLERY
                                </Link>
                            </Menu.Item>

                            <Menu.Item className='footerItem'>
                                <a href='https://lamaith.myshopify.com/'
                                target="_blank" 
                                rel="noopener noreferrer"
                                className='footerLink'>
                                    STORE
                                </a>
                            </Menu.Item>
                            
                            <Menu.Item className='footerItem'>
                                <Link to='/services' className='footerLink'>
                                    SERVICES
                                </Link>
                            </Menu.Item>

                            <Menu.Item className='footerItem'>
                                <Link to='/diary' className='footerLink'> 
                                    BLOG
                                </Link>
                            </Menu.Item>

                            <Menu.Item className='footerItem'>
                                <AuthItem className='footerLink'/>
                            </Menu.Item>

                            {isAuthenticated ?
                                <SubMenu key="SubMenu" 
                                        title="ADMINISTRATIVE TOOLS"
                                        className='footerSubmenu'>
            
                                    <Menu.ItemGroup title="Collection">
                                        <Menu.Item key="CollectionSetting1">
                                            <Link to ='/admin/collections/new'
                                                className='footerSubmenuLink'>
                                                New collection
                                            </Link>
                                        </Menu.Item>
                                    
                                        <Menu.Item key="CollectionSetting2"> 
                                            <Link to='/admin/collections/'
                                                className='footerSubmenuLink'>
                                                Manage collections
                                            </Link>
                                        </Menu.Item>
                                    </Menu.ItemGroup>

                                    <Menu.ItemGroup title="Illustration">
                                        <Menu.Item key="IllustrationSetting1">
                                            <Link to ='/admin/illustrations/new'
                                                className='footerSubmenuLink'>
                                                New illustration
                                            </Link>
                                        </Menu.Item>
                                    
                                        <Menu.Item key="IllustrationSetting2"> 
                                            <Link to='/admin/illustrations/'
                                                className='footerSubmenuLink'>
                                                Manage illustrations
                                            </Link>
                                        </Menu.Item>
                                    </Menu.ItemGroup>

                                    <Menu.ItemGroup title="Diary">
                                        <Menu.Item key="DiarySetting1">
                                            <Link to ='/admin/diary/new'
                                                className='footerSubmenuLink'>
                                                New entry
                                            </Link>
                                        </Menu.Item>
                                    
                                        <Menu.Item key="DiarySetting2"> 
                                            <Link to='/admin/diary/'
                                                className='footerSubmenuLink'>
                                                Manage diary
                                            </Link>
                                        </Menu.Item>
                                    </Menu.ItemGroup>

                                    <Menu.ItemGroup title="Service">
                                        <Menu.Item key="ServiceSetting1">
                                            <Link to ='/admin/services/new'
                                                className='footerSubmenuLink'>
                                                New service
                                            </Link>
                                        </Menu.Item>
                                    
                                        <Menu.Item key="ServiceSetting2"> 
                                            <Link to='/admin/services/'
                                                className='footerSubmenuLink'> 
                                                Manage services
                                            </Link>
                                        </Menu.Item>
                                    </Menu.ItemGroup>
            
                                </SubMenu> : <></>}
                    </Menu>
                    
                    <Row className='socialMediaRow'>
                        <p className='socialMediaHeading'> FOLLOW US </p>

                        <Col className='socialMediaIconsRow'>

                            <a href={instagramURL}
                            target="_blank" 
                            rel="noopener noreferrer">
                                <InstagramLogo className='socialLogo'/>
                            </a>

                            <a href={facebookURL}
                            target="_blank" 
                            rel="noopener noreferrer">
                                <FacebookLogo className='socialLogo'/>
                            </a>

                            <a href={youtubeURL}
                            target="_blank" 
                            rel="noopener noreferrer">
                                <YoutubeLogo className='socialLogo'/>
                            </a>

                            <a href={linkedInURL}
                            target="_blank" 
                            rel="noopener noreferrer">
                                <LinkedInLogo className='socialLogo'/>
                            </a>
                        </Col>
                    </Row>
                </Row>

                </Col>

                <Col className='footerMiddle'>
                    <Row className='illustrationAndCopyrightText'>
                        <FooterIllustration className='footerIllustration'/>
                        <p className='copyrightText'> (C) 2021 LAMAITH ALL RIGHTS RESERVED </p>
                    </Row>
                </Col>

                <Col className='footerRight'>
                    <Row className ='contactDetailsRow'>
                        <p className = 'contactDetailsHeading'> LAMAITH </p>
                        <p className = 'contactDetails'> 23 MACKENZIE STREET, MELBOURNE, VIC 3000 </p>
                        <p className = 'contactDetails'> _ </p>
                        <p className = 'contactDetails'> (+61) 451 252 910 </p>
                        <p className = 'contactDetails'> lamaith.official@gmail.com </p>
                    </Row>

                    <Row className = 'footerRightMenu'>
                        <Menu mode="vertical" className='footerMenu'>

                            <Menu.Item className='footerItem'>
                                <a href={privacyPolicyURL} className='footerLink'>
                                    PRIVACY POLICY
                                </a>
                            </Menu.Item>

                            <Menu.Item className='footerItem'>
                                <a href={termsPolicyURL} className='footerLink'> 
                                    TERMS {'&'} CONDITIONS
                                </a>
                            </Menu.Item>

                            <Menu.Item className='footerItem'>
                                <a href={deliveryPolicyURL} className='footerLink'>
                                    DELIVERY POLICY
                                </a>
                            </Menu.Item>

                            <Menu.Item className='footerItem'>
                                <a href={refundPolicyURL} className='footerLink'>
                                    REFUND {'&'} RETURN POLICY
                                </a>
                            </Menu.Item>
                        </Menu>
                    </Row>

                    <Row className = 'paymentOptionsRow'>
                        <Col>
                            <img src = {visaLogo} className='paymentOptionLogo'/>
                            <img src = {paypalLogo} className='paymentOptionLogo'/>
                            <img src = {mastercardLogo} className='paymentOptionLogo'/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

const { SubMenu } = Menu;

export default class Footer extends React.Component {

    render() {
        return (
            <FooterWithAuth />
        );
    }
}
