import React from 'react';
import FadeIn from 'react-fade-in';
import '../../css/HomeMenu.css';
import {ReactComponent as LamaithLogo} from '../../lamaith-logo.svg';
import { Link } from 'react-router-dom';
import collectionLogo from '../../collection-logo.jpg';
import illustrationLogo from '../../illustration-logo.png';
import diaryLogo from '../../diary-logo.jpg';
import storeLogo from '../../store-logo.jpg';
import serviceLogo from '../../service-logo.jpg';

export default class HomeMenu extends React.Component {
    render() {
        return (
            <FadeIn>
                <div className='HomeMenu'>
                    <div className='logoRow'>
                        <LamaithLogo className='lamaithLogo'/>
                    </div>

                    <div className='cardRow'>
                        <Link to = '/collections'
                              style={linkStyle}
                              className='navLink'>
                            <MenuCard image={collectionLogo}
                                      text='COLLECTION'
                            />
                        </Link>

                        <Link to = '/illustrations'
                              style={linkStyle}
                              className='navLink'>
                            <MenuCard image={illustrationLogo}
                                      text='ILLUSTRΛTION'
                            />
                        </Link>

                        <Link to = '/diary'
                              style={linkStyle}
                              className='navLink'>
                            <MenuCard image={diaryLogo}
                                      text='DIΛRY'
                            />
                        </Link>

                        <a href='https://lamaith.store.simplify.com/'
                           target="_blank" 
                           rel="noopener noreferrer"
                           className='navLink'>
                        
                            <MenuCard image={storeLogo}
                                      text='STORE'
                            />

                        </a>

                        <Link to = '/services/'
                              style={linkStyle}
                              className='navLink'>
                            <MenuCard image={serviceLogo}
                                    text='SERVICE'
                            />
                        </Link>
                    </div>
                </div>

            </FadeIn>
        )
    }
}

class MenuCard extends React.Component {
    render() {
        return (
            <div className='menuCard'>
                <img src={this.props.image}
                     className='cardImage'
                     alt=''
                />
                <p className='cardText'> {this.props.text} </p>
            </div> 
        )
    }
}

const linkStyle = {
    textDecoration: 'none'
}