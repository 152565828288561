import React from 'react';
import customAxios from '../../configs/customAxios.js'
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import '../../css/ProductsManagement.css';
import { Link } from 'react-router-dom';
import Content from '../templates/Content.js';
import FadeIn from 'react-fade-in';
import { Table, Space } from 'antd';

export default class ProductsManagementContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            collectionName: ''
        };
    };

    componentDidMount() {
        customAxios.get(`/collections/${this.props.match.params.collectionId}/products`)
            .then(response => {
                this.setState({
                    products: response.data.products,
                    collectionName: response.data.name,
                });
            })
            .catch( error => {
                console.log(error)
            });
    };

    render() {
        if (!this.state.products) {
            return <MyLoadingScreen text={'Fetching product data...'}/>
        }

        else if (this.state.products.length === 0) {
            return (
                <Content heading={this.state.collectionName}>
                    <p> This collection is currently empty.</p>
                    <Link to={{pathname: `/admin/collections/${this.props.match.params.collectionId}/products/new`}}
                                className='link'
                                id='addProduct' >
                            Add a product
                    </Link>
                    <Link to={{pathname: `/admin/collections/`}}
                                className='link'
                                id='back'>
                            Back to collections
                    </Link>
                </Content>
            )
        }
        else {
            return (
                <Content heading={`List of products in ${this.state.collectionName}`}>
                    <ProductManagement products={this.state.products}
                        collectionId={this.props.match.params.collectionId} />
                    <Link to={{pathname: `/admin/collections/${this.props.match.params.collectionId}/products/new`}}
                        className='link'
                        id='addProduct' >
                        Add a product
                    </Link>
                    <Link to={{pathname: `/admin/collections/`}}
                                className='link'
                                id='back'>
                            Back to collections
                    </Link>
                </Content>
            )
        }
    }
};

class ProductManagement extends React.Component {

    render() {

        const columns = [
            { 
                title: 'Name',
                dataIndex: 'name',
                key: '_id',
                render: text => <p className='productName'> {text} </p>

            },

            {   title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">

                        <Link to={{pathname: `/admin/collections/${this.props.collectionId}/products/${record._id}/update`}}
                            className='link' >
                            Update product
                        </Link>

                    </Space>
                )
            },

        ]
        return (
            <Content>
                <FadeIn>
                    <Table dataSource={this.props.products}
                        columns={columns}
                        pagination={false}/>
                </FadeIn>
            </Content>
        )
    }
}



