import React from 'react';
import HomeIllustration from '../presentationals/HomeIllustration.js';
import HomeMenu from '../presentationals/HomeMenu.js';

export default class HomeContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showIllustration: true
        };
        this.hideIllustration = this.hideIllustration.bind(this)
    }

    hideIllustration() {
        this.setState({
            showIllustration: false
        });
    }

    render() {
        if (this.state.showIllustration) {
            return <HomeIllustration onClick={this.hideIllustration} />
        }
        else {
            return <HomeMenu />
        }
    }
}