import React from 'react';
import { Menu } from 'antd';
//import { SettingOutlined, CopyrightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import '../../css/Header.css';
import {ReactComponent as LamaithLogo} from '../../lamaith-logo-white.svg';

//const { SubMenu } = Menu;

export default class Header extends React.Component {
    render() {
        return (
            <Menu mode="horizontal" className='Header'>

                <Menu.Item className='headerItem' id='logoItem'>
                    <Link to='/' className='headerLink'>
                        <LamaithLogo id='LamaithLogo' />
                    </Link>
                </Menu.Item>

                <Menu.Item className='headerItem'>
                    <Link to='/our_story' className='headerLink'> OUR STORY </Link>
                </Menu.Item>

                <Menu.Item className='headerItem'>
                    <Link to='/collections' className='headerLink'> LOOKBOOK </Link>
                </Menu.Item>

                <Menu.Item className='headerItem'>
                    <Link to='/illustrations' className='headerLink'> GΛLLERY </Link>
                </Menu.Item>

                <Menu.Item className='headerItem'>
                    <a href='https://lamaith.myshopify.com/'
                       target="_blank" 
                       rel="noopener noreferrer"
                       className='headerLink'>
                           STORE
                    </a>
                </Menu.Item>
                
                <Menu.Item className='headerItem'>
                    <Link to='/services' className='headerLink'> SERVICES </Link>
                </Menu.Item>

                <Menu.Item className='headerItem'>
                    <Link to='/diary' className='headerLink'> BLOG </Link>
                </Menu.Item>

                <Menu.Item className='headerItem'>
                    <Link to='/contact_us' className='headerLink'> CONTΛCT US </Link>
                </Menu.Item>

            </Menu>
        );
    }
}
