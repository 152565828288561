import React from 'react';
import customAxios from '../../configs/customAxios.js'
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import { Link } from 'react-router-dom';
import Content from '../templates/Content.js';
import FadeIn from 'react-fade-in';
import '../../css/MyCollections.css';


export default class MyCollectionsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collections: null
        };
    };

    componentDidMount() {
        customAxios.get(`/collections`)
            .then(response => {
                this.setState({
                    collections: response.data
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        if (!this.state.collections) {
            return <MyLoadingScreen text={'Fetching collection data...'}/>
        }
        return <MyCollections collections={this.state.collections} />
    }
};


class MyCollections extends React.Component {
    
    render() {
        const collections = this.props.collections.map((collection) => 
        
        <CollectionBrief collection={collection}
            key={collection._id} />
        );

        return (
            <Content heading={'Lookbook'}>
                <FadeIn className='collectionGrid'>
                    {collections}
                </FadeIn>
            </Content>
        );
    }
}
class CollectionBrief extends React.Component {
    render() {
        return (
            <Link to={{
                pathname: `/collections/${this.props.collection._id}/products`
            }}>

                <div className='collectionBrief'>

                    <img src={this.props.collection.photo} 
                        alt='collection_name'
                        className='collectionThumbnail'
                    />

                    <div className='overlay'>
                        <div className='overlayInner'>
                            <div className='collectionBriefName'>
                                {this.props.collection.name}
                            </div>
                        </div>
                    </div>

                </div>
            </Link>
        )
    }
}