import React from 'react';
//import ReactDOM from 'react-dom';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import customAxios from '../../configs/customAxios.js';
import Content from '../templates/Content.js';
//import { withRouter, Router } from 'react-router-dom';
import '../../css/Form.css';

export default class AddCollection extends React.Component {
    render() {
        return (
            <Content heading='Create a new collection'>
                {CollectionForm(this.props.history.push)}
            </Content>
        );
    }
}
const initialValues = {
    name: '',
    description: '',
    photo: '',
    description_pic_src: '',
}

// Validation schema.
const schema = Yup.object().shape({
    name: Yup.string()
        .required('Please enter a unique name for this collection.')
        .test('test-unique-name', 
            'A collection with the same name already exists',
            function(value) {
                return new Promise((resolve, reject) => {
                    customAxios.get(`/collections/doesExist/${value}`)
                    .then((response) => {
                    // Exists.
                    if (response.data) {resolve(false)}
                    else {resolve(true)};
                    })
                    .catch(() => {
                        resolve(true);
                    })
                })
            },
        ),
    description: Yup.string()
        .required('Please enter a short description for this collection.'),
    material: Yup.string(),
    photo: Yup.string()
        .url('Please enter a valid URL, or leave this field blank.')
});

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className='MyTextInputContainer'>  
            <p className='label'> 
                <label htmlFor={props.id || props.name}>{label} </label>
            </p>
            <input className='text-input' 
                   {...field} {...props} />
            {meta.touched && meta.error ?
            ( <div className='error'> {meta.error} </div> ) : null}
        </div>
  );
}

const CollectionForm = (historyPush) => {
    const handleSubmit = function (values, actions) {
        customAxios.post('/collections/new', values)
            .then(
                (response) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush(`/admin/collections/${response.data.result._id}/products`);
                }, 
                (error) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush(`/request_failure`);
                    console.log(error);
                }
            )
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
                >
                {props => (
                    <Form>

                        <MyTextInput
                            label='Collection name'
                            name='name'
                            type='text'
                            placeholder='Note that collection names must be unique.'
                        />

                        <MyTextInput
                            label='Description'
                            name='description'
                            type='text'
                            placeholder='A short description for your collection.'
                        />

                        <MyTextInput
                            label='Description image'
                            name='description_pic_src'
                            type='text'
                            placeholder='Add an image holding the details of this collection. It will be displayed as the first picture in the Lookbook display (from the top-right).'
                        />

                        { props.values.description_pic_src && 
                            <div>
                                <img className='thumbnail' 
                                    src={props.values.description_pic_src}
                                    alt=''
                                />
                                <br/>
                            </div>
                        }

                        <MyTextInput
                            label='Photo'
                            name='photo'
                            type='text'
                            placeholder='URL to your photo. Defaults to a 
                            placeholder image if left blank.'
                        />

                        { props.values.photo && 
                            <div>
                                <img className='thumbnail' 
                                    src={props.values.photo}
                                    alt=''
                                />
                                <br/>
                            </div>
                        }

                        <button type='submit'
                            className='submitButton'
                        >
                            Submit
                        </button>

                    </Form>
                )}
            </Formik>
    </>
  );
};

