import React from 'react';
import Content from '../templates/Content.js';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class MyLoadingScreen extends React.Component {
    render() {
        return (
            <Content heading={'Fetching data...'}>
                Please wait for a moment... <span> <Spin indicator={loadingIcon} /> </span>
                <br/>
                If this page does not automatically refresh, an error(s) may have occurred.
                <br/>
                In that case, please contact the website's administrator, or try again later.
            </Content>
        )
    }
}