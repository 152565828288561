import React from 'react';
import '../../css/Content.css';
import FadeIn from 'react-fade-in';

// Template for a content page.
export default class Content extends React.Component {
    render() {
        return (
            <FadeIn>  
                <div className='contentHeadingContainer'>
                    <p className='contentHeading'> {this.props.heading} </p>
                </div>
                
                <div className='contentContainer'>
        
                    <div className='contentWrap'>
                        {this.props.children}
                    </div>

                </div>
            </FadeIn>
        )
    }
}