/*
** Form for adding a new product to a collection.
*/

import React from 'react';
import FadeIn from 'react-fade-in';
import { Formik, Form, useField, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import customAxios from '../../configs/customAxios.js';
import Content from '../templates/Content.js';
import '../../css/Form.css';

export default class AddProduct extends React.Component {
    render() {
        return (
            <Content heading='Add a new product to this collection'>
                {ProductForm(
                    this.props.match.params.collectionId,
                    this.props.history.push
                )}
            </Content>
        );
    }
}
const initialValues = {
    name: '',
    category: '',
    sizeChart: '',
    material: '',
    price: null,
    photos: []
}

// Validation schema.
const schema = Yup.object().shape({
    name: Yup.string()
        .required('Please enter a name for this product.'),
    //category: Yup.string()
        //.required('Please enter a category for this product.')
});

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className='MyTextInputContainer'>  
            <p className='label'> 
                <label htmlFor={props.id || props.name}>{label} </label>
            </p>
            <input className='text-input' 
                   {...field} {...props} />
            {meta.touched && meta.error ?
            ( <div className='error'> {meta.error} </div> ) : null}
        </div>
  );
}

const ProductForm = (collectionId, historyPush) => {
    const handleSubmit = function (values, actions) {
        customAxios.put(`/collections/${collectionId}/products/new`, values)
            .then(
                (response) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush(`/admin/collections/${collectionId}/products`);
                }, 
                (error) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    console.log(error);
                }
            )
    };
    return (
        
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                {props => (
                    <Form>

                        <MyTextInput
                            label='Product name'
                            name='name'
                            type='text'
                            placeholder='Enter a name for this product.'
                        />

                        {/*
                        <MyTextInput
                            label='Category'
                            name='category'
                            type='text'
                            placeholder='Avoid duplicate and inconsistent categorisations.'
                        />

                        <MyTextInput
                            label='Size chart'
                            name='sizeChart'
                            type='text'
                            placeholder='URL to the size chart of this product.'
                        />

                        <MyTextInput
                            label='Material'
                            name='material'
                            type='text'
                            placeholder='Recommended if you would like to sell this product.'
                        />

                        <MyTextInput
                            label='Price'
                            name='price'
                            type='number'
                            placeholder='Priced products will automatically appear in the store. Left blank or enter 0 if you would like for this product to remain collection-exclusive.'
                        />
                        */}

                        <label> Photos </label>
                        <FieldArray name="photos"  
                            render={arrayHelpers => (
                                <div>
                                    {props.values.photos && props.values.photos.length > 0 ? (
                                        props.values.photos.map((photo, index) => (
                                            <FadeIn>
                                                <div key={index}>
                                                    <Field name={`photos.${index}`} 
                                                        className='photoField'
                                                    />
                                                    
                                                    {photo && 
                                                        <div>
                                                            <img className='thumbnail' 
                                                                src={photo}
                                                                alt=''
                                                            />
                                                            <br/>
                                                        </div>
                                                    }

                                                    <button
                                                        type='button'
                                                        className='removeButton'
                                                        onClick={() => arrayHelpers.remove(index)} // remove a photo from the list
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </FadeIn>
                                        ))) : null 
                                    }
                                    <button type='button'
                                        className='addButton'
                                        onClick={() => arrayHelpers.push('')}>
                                        Add a photo
                                    </button>
                                </div>
                            )}
                        />
                        <button type='submit'
                            className='submitButton'
                        >
                            Submit
                        </button>

                    </Form>
                )}
            </Formik>
        </div>
    );
};

