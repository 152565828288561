import axios from 'axios';

const env = process.env.NODE_ENV; // current environment

const customAxios = axios.create({
    baseURL: 
        env === 'production'
        ? 'https://lamaith-5dxhc2n36-hmai2139.vercel.app/api' // production
        : 'http://localhost:3000/api/', // development
});

export default customAxios;

