import React from 'react';
import customAxios from '../../configs/customAxios.js'
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import FadeIn from 'react-fade-in';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import '../../css/Diary.css';

export default class DiaryEntryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entry: null,
        }
    }

    componentDidMount() {
        customAxios.get(`/diary/${this.props.match.params.entryId}`)
        .then(response => {
            this.setState({
                entry: response.data,
            });
        })
        .catch(error => {
            return (error);
        })
    }

    render() {

        if (!this.state.entry) {
            return (
                <MyLoadingScreen text='Fetching entry data...'/>
            );
        }

        else {
            return (
                <DiaryEntry entry={this.state.entry} />
            );
        }
    }
}

class DiaryEntry extends React.Component {
    render() {
        return (
            <FadeIn>
                <div className='diary'>
                    <div className='entry'>
                        <p className='entryTitle'>
                            {this.props.entry.name}
                        </p>

                        <p className='entryDate'>
                            {moment(this.props.entry.createdAt)
                                .format('MMMM Do YYYY, h:mm a')}
                        </p>

                        {ReactHtmlParser(this.props.entry.content)}
                    </div>
                </div>
            </FadeIn>
        )
    }
}