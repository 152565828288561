// Libraries.
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// Stylesheets.
import "./css/App.css";
import "antd/dist/antd.css";

// Home component(s).
import HomeContainer from "./components/containers/HomeContainer.js";

// Collection component(s).
import MyCollectionsContainer from "./components/containers/MyCollectionsContainer.js";
import CollectionContainer from "./components/containers/CollectionContainer.js";

// Illustration component(s).
import MyIllustrationsContainer from "./components/containers/MyIllustrationsContainer.js";
import IllustrationContainer from "./components/containers/IllustrationContainer.js";

// Diary component(s).
import MyDiaryContainer from "./components/containers/MyDiaryContainer.js";
import DiaryEntryContainer from "./components/containers/DiaryEntryContainer.js";

// Service component(s).
import ServiceContainer from "./components/containers/ServiceContainer.js";

// Form components.
import AddCollection from "./components/forms/AddCollection.js";
import AddProduct from "./components/forms/AddProduct.js";
import AddIllustration from "./components/forms/AddIllustration.js";
import AddDiaryEntry from "./components/forms/AddDiaryEntry.js";
import AddService from "./components/forms/AddService.js";

import UpdateCollection from "./components/forms/UpdateCollection.js";
import UpdateProduct from "./components/forms/UpdateProduct.js";
import UpdateIllustration from "./components/forms/UpdateIllustration.js";
import UpdateDiaryEntry from "./components/forms/UpdateDiaryEntry.js";
import UpdateService from "./components/forms/UpdateService.js";

// Functional components.
import OurStory from "./components/presentationals/OurStory.js";
import Header from "./components/presentationals/Header.js";
import Footer from "./components/presentationals/Footer.js";
import ContactUs from "./components/presentationals/ContactUs.js";
import ServerResponse from "./components/templates/ServerResponse.js";

// Admin components.
import CollectionManagementContainer from "./components/admins/CollectionManagement.js";
import ProductsManagementContainer from "./components/admins/ProductsManagement.js";
import IllustrationManagementContainer from "./components/admins/IllustrationManagement.js";
import DiaryManagementContainer from "./components/admins/DiaryManagement.js";
import ServiceManagementContainer from "./components/admins/ServiceManagement.js";

// Auth components.
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory.js";
import ProtectedRoute from "./auth/ProtectedRoute";

// ------------------------------------------------------------------------ //
export default class App extends React.Component {
  render() {
    return (
      <Router basename='/'>
        <Auth0ProviderWithHistory>
          <Header />
          <div className="App">
            <Switch>
              {/* Home. */}
              <Route exact path="/" component={HomeContainer} />

              {/* Shows all collections. */}
              <Route
                exact
                path="/collections"
                component={MyCollectionsContainer}
              />

              {/* Detailed page of a collection. */}
              <Route
                exact
                path="/collections/:collectionId/products"
                component={CollectionContainer}
              />

              {/* Shows all illustations. */}
              <Route
                exact
                path="/illustrations"
                component={MyIllustrationsContainer}
              />

              {/* Detailed page of an illustration. */}
              <Route
                exact
                path="/illustrations/:illustrationId"
                component={IllustrationContainer}
              />

              {/* Shows all diary entries. */}
              <Route exact path="/diary" component={MyDiaryContainer} />

              {/* Detailed page of a diary entry. */}
              <Route
                exact
                path="/diary/:entryId"
                component={DiaryEntryContainer}
              />

              {/* Shows all services. */}
              <Route exact path="/services" component={ServiceContainer} />

              {/* Routes with a functional component */}
              <Route exact path="/our_story" component={OurStory} />

              <Route exact path="/contact_us" component={ContactUs} />

              {/* ----------------- ADMIN ----------------------- */}

              {/* Manage collections page */}
              <ProtectedRoute
                exact
                path="/admin/collections/"
                component={CollectionManagementContainer}
              />

              {/* Manage products page */}
              <ProtectedRoute
                exact
                path="/admin/collections/:collectionId/products"
                component={ProductsManagementContainer}
              />

              {/* Add a new collection page. */}
              <ProtectedRoute
                exact
                path="/admin/collections/new"
                component={AddCollection}
              />

              {/* Add a new product to a collection page */}
              <ProtectedRoute
                exact
                path="/admin/collections/:collectionId/products/new"
                component={AddProduct}
              />

              {/* Update an existing collection. */}
              <ProtectedRoute
                exact
                path="/admin/collections/:collectionId/update"
                component={UpdateCollection}
              />

              {/* Update an existing product. */}
              <ProtectedRoute
                exact
                path="/admin/collections/:collectionId/products/:productId/update"
                component={UpdateProduct}
              />

              {/* Manage illustrations page */}
              <ProtectedRoute
                exact
                path="/admin/illustrations/"
                component={IllustrationManagementContainer}
              />

              {/* Add a new illustration page. */}
              <ProtectedRoute
                exact
                path="/admin/illustrations/new"
                component={AddIllustration}
              />

              {/* Update an illustration page. */}
              <ProtectedRoute
                exact
                path="/admin/illustrations/:illustrationId/update"
                component={UpdateIllustration}
              />

              {/* Manage illustrations page */}
              <ProtectedRoute
                exact
                path="/admin/diary/"
                component={DiaryManagementContainer}
              />

              {/* Add a new diary entry page. */}
              <ProtectedRoute
                exact
                path="/admin/diary/new"
                component={AddDiaryEntry}
              />

              {/* Update a diary entry page. */}
              <ProtectedRoute
                exact
                path="/admin/diary/:entryId/update"
                component={UpdateDiaryEntry}
              />

              {/* Manage services page */}
              <ProtectedRoute
                exact
                path="/admin/services/"
                component={ServiceManagementContainer}
              />

              {/* Add a new service page. */}
              <ProtectedRoute
                exact
                path="/admin/services/new"
                component={AddService}
              />

              {/* Update a service page. */}
              <ProtectedRoute
                exact
                path="/admin/services/:serviceId/update"
                component={UpdateService}
              />

              {/* ----------------------------------------------- */}

              {/* Server responses */}
              <Route
                exact
                path="/request_success"
                render={(props) => (
                  <ServerResponse
                    {...props}
                    heading={"Success"}
                    message={"Your request has succeeded."}
                  />
                )}
              />

              <Route
                path="/request_failure"
                render={(props) => (
                  <ServerResponse
                    {...props}
                    heading={"Failure"}
                    message={"Your request has failed. Please try again."}
                  />
                )}
              />

              {/* 404 Not found */}
              <Route
                render={(props) => (
                  <ServerResponse
                    {...props}
                    heading={"404 Not found"}
                    message={""}
                  />
                )}
              />
            </Switch>
          </div>
          <Footer />
        </Auth0ProviderWithHistory>
      </Router>
    );
  }
}
