import React from 'react';
import { Formik, Form, useField, Field } from 'formik';
import * as Yup from 'yup';
import customAxios from '../../configs/customAxios.js';
import Content from '../templates/Content.js';
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import '../../css/Form.css';
import { Editor } from '@tinymce/tinymce-react';

export default class UpdateDiaryEntry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: null
        }
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.deleteSelf = this.deleteSelf.bind(this);
    }

    componentDidMount() {
        customAxios.get(`/diary/${this.props.match.params.entryId}`)
        .then(response => {
            this.setState({
                entry: response.data
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

    deleteSelf() {
            customAxios.delete(
                `/diary/${this.props.match.params.entryId}`)
            .then((response) => {
                this.props.history.goBack();
            }, 
            (error) => {
                console.log(error);
                this.props.history.push('/request_failure');
            })
        console.log('OK');
    }
    
    showDeleteConfirm() {
        const deleteSelf = this.deleteSelf;
        Modal.confirm({
            title: 'Confirm deletion',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone',
            onOk() {
                deleteSelf();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {

        if (!this.state.entry) {
            return (
                <MyLoadingScreen text={'Fetching entry data...'} />
            )
        }

        else {
            return (
                <Content heading={`Update ${this.state.entry.name}`}>
                    {DiaryEntryUpdateForm(
                        this.props.match.params.entryId,
                        this.state.entry,
                        this.props.history.push)}
                    <Button onClick={this.showDeleteConfirm}>
                        Delete this entry
                    </Button>
                </Content>
            );
        }
    }
}

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className='MyTextInputContainer'>  
            <p className='label'> 
                <label htmlFor={props.id || props.name}>{label} </label>
            </p>
            <input className='text-input' 
                   {...field} {...props} />
            {meta.touched && meta.error ?
            ( <div className='error'> {meta.error} </div> ) : null}
        </div>
  );
}
// Validation schema.
const schema = Yup.object().shape({
    name: Yup.string()
        .required('Please enter a name for this entry.'),
    content: Yup.string()
        .required('Content cannot be left blank.'),
    image_src: Yup.string()
        .required('Please add an image URL for this entry.'),
    description: Yup.string()
        .required('Enter a brief description for this entry.')
});

const DiaryEntryUpdateForm = (entryId, initialValues, historyPush) => {

    // Submission logic.
    const handleSubmit = function (values, actions) {
        const updatedDiaryEntry = {
            name: values.name,
            content: values.content,
            image_src: values.image_src,
            description: values.description,
        }
        customAxios.put(`/diary/${entryId}/update`, updatedDiaryEntry)
            .then(
                (response) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush('/request_success');
                }, 
                (error) => {
                    console.log(error);
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush('/request_failure');
                }
            )
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                {props => (
                    <Form>

                        <MyTextInput
                            label='Title'
                            name='name'
                            type='text'
                            placeholder='Enter a title for this entry.'
                        />

                        <label> Content </label>

                        { props.values.photo && 
                            <div>
                                <img className='thumbnail' 
                                    src={props.values.photo}
                                    alt=''
                                />
                                <br/>
                            </div>
                        }
                        <Field name='content'>
                            {({ field, meta }) => (
                                <div>
                                    <Editor
                                        apiKey='9jhf90fmocn4tl9d6qkfxhqosjfngw6lvqc7t4dgux4mqv1e'
                                        initialValue={initialValues.content}
                                        init={{
                                            height: '500',
                                            menubar: true,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                // eslint-disable-next-line
                                                'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onEditorChange={(e) => {
                                            props.handleChange({ target: { name: 'content', value: e } })
                                        }}
                                    />
                                </div>)}
                        </Field>

                        <MyTextInput
                            label='Image'
                            name='image_src'
                            type='text'
                            placeholder='Add an image URL for this entry.'
                        />

                        { props.values.image_src && 
                            <div>
                                <img className='thumbnail' 
                                    src={props.values.image_src}
                                    alt=''
                                />
                                <br/>
                            </div>
                        }

                        <MyTextInput
                            label='Description'
                            name='description'
                            type='text'
                            placeholder='Enter a brief description for this entry.'
                        />

                        <button type='submit'
                            className='submitButton'
                        >
                            Submit
                        </button>

                    </Form>
                )}
            </Formik>
        </div>
    );
};
