import React from 'react';
import '../../css/HomeIllustration.css';
export default class HomeIllustration extends React.Component {
    
    render() {
        return  (
            <div className = 'Home'>
             
            </div>
        )
    }
}
