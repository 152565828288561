// Update this to be similar to Diary.

import Content from '../templates/Content.js';
import React from 'react';
import ourStory from '../../lamaith-our-story.png';

export default class OurStory extends React.Component {
    render() {
        return (
            <div className='ourStoryDiv'>
                <Content heading='Our story'>
                    <img alt='our-story' src={ourStory} style={ourStoryStyle}/>
                </Content>
            </div>
        )
    }
}

const ourStoryStyle = {
    marginLeft: '5%',
    width: '90%',
}