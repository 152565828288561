import React from 'react';
import customAxios from '../../configs/customAxios.js'
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import '../../css/Service.css';
import Content from '../templates/Content.js';
import FadeIn from 'react-fade-in';
import ReactHtmlParser from 'react-html-parser';
import ContactDetails from '../presentationals/ContactDetails.js';

export default class ServicesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services: null
        };
    };

    componentDidMount() {
        customAxios.get(`/services`)
            .then(response => {
                this.setState({
                    services: response.data,
                });
            })
            .catch( error => {
                return (error);
            });
    };

    render() {
        if (!this.state.services) {
            return <MyLoadingScreen text={'Fetching service data...'}/>
        }

        else if (this.state.services.length === 0) {
            return (
                <Content heading={'The services we provide at Lamaith'}>
                    <p> There are no services yet. </p>
                    <ContactDetails />
                </Content>
            )
        }
        else {
            return (
                <Services services={this.state.services} />
            )
        }
    }
};

class Services extends React.Component {
    render() {
        const services = this.props.services.map(service => {
            return (
                <div className='service' key={service._id}>
                    <p className='serviceName'> {service.name}</p>
                    {ReactHtmlParser(service.description)}
                </div>
            )});

        return (
            <Content heading={'The services we provide at Lamaith'}>
                <FadeIn>
                    {services}
                </FadeIn>
                <ContactDetails />
            </Content>
        )
    }
}