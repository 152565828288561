import React from 'react';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import customAxios from '../../configs/customAxios.js';
import Content from '../templates/Content.js';
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import '../../css/Form.css';

export default class UpdateCollection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collection: null
        }
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.deleteSelf = this.deleteSelf.bind(this);
    }

    componentDidMount() {
        customAxios.get(`/collections/${this.props.match.params.collectionId}`)
        .then(response => {
            this.setState({
                collection: response.data
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

    deleteSelf() {
            customAxios.delete(
                `/collections/${this.props.match.params.collectionId}`)
            .then((response) => {
                this.props.history.goBack();
            }, 
            (error) => {
                console.log(error);
                this.props.history.push('/request_failure');
            })
        console.log('OK');
    }
    
    showDeleteConfirm() {
        const deleteSelf = this.deleteSelf;
        Modal.confirm({
            title: 'Confirm deletion',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone',
            onOk() {
                deleteSelf();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {

        if (!this.state.collection) {
            return (
                <MyLoadingScreen text={'Fetching collection data...'} />
            )
        }

        else {
            return (
                <Content heading={`Update ${this.state.collection.name}`}>
                    {CollectionUpdateForm(
                        this.props.match.params.collectionId,
                        this.state.collection,
                        this.props.history.push)}
                    <Button onClick={this.showDeleteConfirm}>
                        Delete this collection
                    </Button>
                </Content>
            );
        }
    }
}

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className='MyTextInputContainer'>  
            <p className='label'> 
                <label htmlFor={props.id || props.name}>{label} </label>
            </p>
            <input className='text-input' 
                   {...field} {...props} />
            {meta.touched && meta.error ?
            ( <div className='error'> {meta.error} </div> ) : null}
        </div>
  );
}

const CollectionUpdateForm = (collectionId, initialValues, historyPush) => {

    // Submission logic.
    const handleSubmit = function (values, actions) {
        const updatedCollection = {
            name: values.name,
            description: values.description,
            photo: values.photo,
            description_pic_src: values.description_pic_src,
        }
        customAxios.put(`/collections/${collectionId}/update`, updatedCollection)
            .then(
                (response) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush('/request_success');
                }, 
                (error) => {
                    console.log(error);
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush('/request_failure');
                }
            )
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={ Yup.object().shape({
                    name: Yup.string()
                        .required('Please enter a unique name for this collection.')
                        .test('test-unique-name', 
                            'A collection with the same name already exists',
                            function(value) {
                                return new Promise((resolve, reject) => {
                                    customAxios.get(`/collections/doesExist/${value}`)
                                    .then((response) => {
                                        // Exists.
                                        if (response.data && value !== initialValues.name) {
                                            resolve(false);
                                        }
                                        else {
                                            resolve(true);
                                        }
                                    })
                                    .catch(() => {
                                        resolve(true);
                                    })
                                })
                            },
                        ),
                    description: Yup.string()
                        .required('Please enter a short description for this collection.'),
                    material: Yup.string()
                })}
                onSubmit={handleSubmit}
                >
                { props => (
                    <Form>

                        <MyTextInput
                            label='Collection name'
                            name='name'
                            type='text'
                            placeholder='Note that collection names must be unique.'
                        />

                        <MyTextInput
                            label='Description'
                            name='description'
                            type='text'
                            placeholder='A short description for your collection.'
                        />

                        <MyTextInput
                            label='Description image'
                            name='description_pic_src'
                            type='text'
                            placeholder='Add an image holding the details of this collection. It will be displayed as the first picture in the Lookbook display (from the top-right).'
                        />
                        
                        { props.values.description_pic_src && 
                            <div>
                                <img className='thumbnail' 
                                    src={props.values.description_pic_src}
                                    alt=''
                                />
                                <br/>
                            </div>
                        }

                        <MyTextInput
                            label='Photo'
                            name='photo'
                            type='text'
                            placeholder='URL to your photo. Defaults to a 
                            placeholder image if left blank.'
                        />

                        { props.values.photo && 
                            <div>
                                <img className='thumbnail' 
                                    src={props.values.photo}
                                    alt=''
                                />
                                <br/>
                            </div>
                        }

                        <button type='submit'
                                className='submitButton'
                        >
                            Update
                        </button>

                    </Form>
                )}

            </Formik>
        </>
    );
};
