import React from 'react';
import customAxios from '../../configs/customAxios.js'
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import { Link } from 'react-router-dom';
import Content from '../templates/Content.js';
import FadeIn from 'react-fade-in';
import { Table, Space } from 'antd';

export default class IllustrationManagementContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            illustrations: null
        };
    };

    componentDidMount() {
        customAxios.get(`/illustrations`)
            .then(response => {
                this.setState({
                    illustrations: response.data,
                });
            })
            .catch( error => {
                return (error);
            });
    };

    render() {
        if (!this.state.illustrations) {
            return <MyLoadingScreen text={'Fetching illustration data...'}/>
        }

        else if (this.state.illustrations.length === 0) {
            return (
                <Content heading={'Gallery'}>
                    <p> There are no illustrations yet. </p>
                    <Link to={{pathname: `/admin/illustrations/new`}}
                        className='link'
                        id='addIllustration' >
                        Add an illustration
                    </Link>
                </Content>
            )
        }
        else {
            return (
                <Content heading={`Manage gallery`}>
                    <IllustrationManagement 
                        illustrations={this.state.illustrations}
                    />
                    <Link to={{pathname: `/admin/illustrations/new`}}
                        className='link'
                        id='addIllustration' >
                        Add an illustration
                    </Link>
                </Content>
            )
        }
    }
};

class IllustrationManagement extends React.Component {

    render() {

        const columns = [
            { 
                title: 'Name',
                dataIndex: 'name',
                key: '_id',
                render: text => <p className='illustrationName'> {text} </p>

            },

            {   title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle" key={record._id}>

                        <Link to={{pathname: `/admin/illustrations/${record._id}/update`}}
                            className='link'>
                            Update illustration
                        </Link>

                        <Link to={{pathname: `/illustrations/${record._id}`}}
                            className='link'>
                            View illustration
                        </Link>
                    </Space>
                )
            },

        ]
        return (
            <Content>
                <FadeIn>
                    <Table dataSource={this.props.illustrations}
                        columns={columns}
                        pagination={false}/>
                </FadeIn>
            </Content>
        )
    }
}



