import React from 'react';
import customAxios from '../../configs/customAxios.js'
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import { Link } from 'react-router-dom';
import Content from '../templates/Content.js';
import FadeIn from 'react-fade-in';
import { Table, Space } from 'antd';

export default class ServiceManagementContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services: null
        };
    };

    componentDidMount() {
        customAxios.get(`/services`)
            .then(response => {
                this.setState({
                    services: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        if (!this.state.services) {
            return <MyLoadingScreen text={'Fetching service data...'}/>
        }

        else if (this.state.services.length === 0) {
            return (
                <Content heading={'Service'}>
                    <p> There are no services yet. </p>
                    <Link to={{pathname: `/admin/services/new`}}
                        className='link'
                        id='addService' >
                        Add a service
                    </Link>
                </Content>
            )
        }
        else {
            return (
                <Content heading={`Services`}>
                    <ServiceManagement 
                        services={this.state.services}
                    />
                    <Link to={{pathname: `/admin/services/new`}}
                        className='link'
                        id='addService' >
                        Add a service
                    </Link>
                </Content>
            )
        }
    }
};

class ServiceManagement extends React.Component {

    render() {

        const columns = [
            { 
                title: 'Name',
                dataIndex: 'name',
                key: '_id',
                render: text => <p> {text} </p>

            },

            {   title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle" key={record._id}>

                        <Link to={{pathname: `/admin/services/${record._id}/update`}}
                            className='link' >
                            Update service
                        </Link>

                    </Space>
                )
            },

        ]
        return (
            <Content>
                <FadeIn>
                    <Table dataSource={this.props.services}
                        columns={columns}
                        pagination={false}/>
                </FadeIn>
            </Content>
        )
    }
}



