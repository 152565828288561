/*
** Form for adding a new diary entry.
*/

import React from 'react';
import { Formik, Form, useField, Field } from 'formik';
import * as Yup from 'yup';
import customAxios from '../../configs/customAxios.js';
import Content from '../templates/Content.js';
import '../../css/Form.css';
import { Editor } from '@tinymce/tinymce-react';

export default class AddDiaryEntry extends React.Component {
    render() {
        return (
            <Content heading='Add a new entry'>
                {DiaryEntryForm(this.props.history.push)}
            </Content>
        );
    }
}
const initialValues = {
    name: '',
    content: '',
    image_src: '',
}

// Validation schema.
const schema = Yup.object().shape({
    name: Yup.string()
        .required('Please enter a name for this entry.'),
    content: Yup.string()
        .required('Content cannot be left blank.'),
    image_src: Yup.string()
        .required('Please add an image URL for this entry.'),
    description: Yup.string()
        .required('Enter a brief description for this entry.')
});

const MyTextInput = ({ label, ...props }) => {

    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);

    return (
        <div className='MyTextInputContainer'>

            <p className='label'> 
                <label htmlFor={props.id || props.name}>{label} </label>
            </p>

            <input className='text-input' 
                   {...field} {...props} />
            {meta.touched && meta.error ?
            ( <div className='error'> {meta.error} </div> ) : null}
        </div>
  );
}

const DiaryEntryForm = (historyPush) => {
    const handleSubmit = function (values, actions) {
        customAxios.post(`/diary/new`, values)
            .then(
                (response) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush(`/admin/diary`);
                }, 
                (error) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    console.log(error);
                }
            )
    };

    return (
        
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                {props => (
                    <Form>

                        <MyTextInput
                            label='Title'
                            name='name'
                            type='text'
                            placeholder='Enter a title for this entry.'
                        />

                        <label> Content </label>

                        <Field name='content'>
                            {({ field, meta }) => (
                                <div>
                                    <Editor
                                        apiKey='9jhf90fmocn4tl9d6qkfxhqosjfngw6lvqc7t4dgux4mqv1e'
                                        initialValue=''
                                        init={{
                                            selector: '#mytextarea',
                                            height: 500,
                                            menubar: true,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                // eslint-disable-next-line
                                                'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onEditorChange={(e) => {
                                            props.handleChange({ target: { name: 'content', value: e } })
                                        }}
                                    />
                                </div>)}
                        </Field>

                        <MyTextInput
                            label='Image'
                            name='image_src'
                            type='text'
                            placeholder='Add an image URL for this entry.'
                        />

                        { props.values.image_src && 
                            <div>
                                <img className='thumbnail' 
                                    src={props.values.image_src}
                                    alt=''
                                />
                                <br/>
                            </div>
                        }

                        <MyTextInput
                            label='Description'
                            name='description'
                            type='text'
                            placeholder='Enter a brief description for this entry.'
                        />

                        <button type='submit'
                            className='submitButton'
                        >
                            Submit
                        </button>

                    </Form>
                )}
            </Formik>
        </div>
    );
};

