/*
** Form for adding a new illustrations.
*/

import React from 'react';
import { Formik, Form, useField} from 'formik';
import * as Yup from 'yup';
import customAxios from '../../configs/customAxios.js';
import Content from '../templates/Content.js';
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import '../../css/Form.css';

export default class UpdateIllustration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            illustration: null
        }
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.deleteSelf = this.deleteSelf.bind(this);
    }

    componentDidMount() {
        customAxios.get(`/illustrations/${this.props.match.params.illustrationId}`)
        .then(response => {
            console.log(response.data);

            this.setState({
                illustration: response.data
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

    deleteSelf() {
            customAxios.delete(
                `/collections/${this.props.match.params.illustrationId}`)
            .then((response) => {
                this.props.history.goBack();
            }, 
            (error) => {
                console.log(error);
                this.props.history.push('/request_failure');
            })
        console.log('OK');
    }
    
    showDeleteConfirm() {
        const deleteSelf = this.deleteSelf;
        Modal.confirm({
            title: 'Confirm deletion',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone',
            onOk() {
                deleteSelf();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {

        if (!this.state.illustration) {
            return (
                <MyLoadingScreen text={'Fetching illustration data...'} />
            )
        }

        else {
            return (
                <Content heading={`Update ${this.state.illustration.name}`}>
                    {IllustrationUpdateForm(
                        this.props.match.params.illustrationId,
                        this.state.illustration,
                        this.props.history.push)}
                    <Button onClick={this.showDeleteConfirm}>
                        Delete this illustration
                    </Button>
                </Content>
            );
        }
    }
}

// Validation schema.
const schema = Yup.object().shape({
    name: Yup.string()
        .required('Please enter a name for this illustration.'),
    src: Yup.string()
        .required('Illustration URL cannot be left blank.')
        .url('Please enter a valid URL.')
});

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className='MyTextInputContainer'>  
            <p className='label'> 
                <label htmlFor={props.id || props.name}>{label} </label>
            </p>
            <input className='text-input' 
                   {...field} {...props} />
            {meta.touched && meta.error ?
            ( <div className='error'> {meta.error} </div> ) : null}
        </div>
  );
}

const MyTextArea = ({label, ...props}) => {

    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);

    return (
        <>
            <label htmlFor={props.id || props.name}> 
                {label}
            </label>

            <textarea className="textArea" {...field} {...props} />

            {meta.touched && meta.error ? (
                <div className="error">{meta.error} </div>
            ) : null}
        </>
    );
};

const IllustrationUpdateForm = (illustrationId, initialValues, historyPush) => {

    // Submission logic.
    const handleSubmit = function (values, actions) {
        const updatedIllustration = {
            name: values.name,
            category: values.category,
            src: values.src,
            context: values.context
        }
        customAxios.put(`/illustrations/${illustrationId}/update`, updatedIllustration)
            .then(
                (response) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush('/request_success');
                }, 
                (error) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush('/request_failure');
                    console.log(error);
                }
            )
    };

    return (
        
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                {props => (
                    <Form>

                        <MyTextInput
                            label='Illustration name'
                            name='name'
                            type='text'
                            placeholder='Enter a name for this illustration.'
                        />

                        <MyTextInput
                            label='Illustration category'
                            name='category'
                            type='text'
                            placeholder='Enter a category for this illustration e.g Portrait/Fashion Illustration/Illustration.'
                        />

                        <MyTextArea
                            label='Context'
                            name='context'
                            type='text'
                            placeholder='Optional context/description/story/whatever for this illustration.'
                        />

                        <MyTextInput
                            label='Illustration URL'
                            name='src'
                            type='text'
                            placeholder='URL to your illustration.'
                        />

                        { props.values.src && 
                            <div>
                                <img className='thumbnail' 
                                    src={props.values.src}
                                    alt=''
                                />
                                <br/>
                            </div>
                        }

                        <button type='submit'
                            className='submitButton'
                        >
                            Update
                        </button>

                    </Form>
                )}
            </Formik>
        </div>
    );
};

