/*
** Form for adding a new illustration.
*/

import React from 'react';
import { Formik, Form, useField} from 'formik';
import * as Yup from 'yup';
import customAxios from '../../configs/customAxios.js';
import Content from '../templates/Content.js';
import '../../css/Form.css';

export default class AddIllustration extends React.Component {
    render() {
        return (
            <Content heading='Add a new illustration'>
                {IllustrationForm(this.props.history.push)}
            </Content>
        );
    }
}
const initialValues = {
    name: '',
    src: ''
}

// Validation schema.
const schema = Yup.object().shape({
    name: Yup.string()
        .required('Please enter a name for this illustration.'),
    src: Yup.string()
        .required('Illustration URL cannot be left blank.')
        .url('Please enter a valid URL.')
});

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className='MyTextInputContainer'>  
            <p className='label'> 
                <label htmlFor={props.id || props.name}>{label} </label>
            </p>
            <input className='text-input' 
                   {...field} {...props} />
            {meta.touched && meta.error ?
            ( <div className='error'> {meta.error} </div> ) : null}
        </div>
  );
}

const MyTextArea = ({label, ...props}) => {

    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);

    return (
        <>
            <label htmlFor={props.id || props.name}> 
                {label}
            </label>

            <textarea className="textArea" {...field} {...props} />

            {meta.touched && meta.error ? (
                <div className="error">{meta.error} </div>
            ) : null}
        </>
    );
};

const IllustrationForm = (historyPush) => {
    const handleSubmit = function (values, actions) {
        customAxios.post(`/illustrations/new`, values)
            .then(
                (response) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush(`/admin/illustrations`);
                }, 
                (error) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    console.log(error);
                }
            )
    };
    return (
        
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                {props => (
                    <Form>

                        <MyTextInput
                            label='Illustration name'
                            name='name'
                            type='text'
                            placeholder='Enter a name for this illustration.'
                        />

                        <MyTextInput
                            label='Illustration category'
                            name='category'
                            type='text'
                            placeholder='Enter a category for this illustration e.g Portrait/Fashion Illustration/Illustration.'
                        />

                        <MyTextArea
                            label='Context'
                            name='context'
                            type='text'
                            placeholder='Optional context/description/story/whatever for this illustration.'
                        />

                        <MyTextInput
                            label='Illustration URL'
                            name='src'
                            type='text'
                            placeholder='URL to your illustration'
                        />

                        { props.values.src && 
                            <div>
                                <img className='thumbnail' 
                                    src={props.values.src}
                                    alt=''
                                />
                                <br/>
                            </div>
                        }

                        <button type='submit'
                            className='submitButton'
                        >
                            Submit
                        </button>

                    </Form>
                )}
            </Formik>
        </div>
    );
};

