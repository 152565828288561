import React from 'react';
import Content from '../templates/Content.js';
import ContactDetails from './ContactDetails.js';
import {ReactComponent as ContactUsIllustration} from '../../contact-us.svg';
import '../../css/ContactUs.css';

export default class ContactUs extends React.Component {
    render() {
        return (
            <Content heading='Contact us'>
                <ContactUsIllustration className='contactUsIllustration'/>
                <ContactDetails />
                <p className='contactMessage'> WE LOOK FORWARD TO WORKING TOGETHER!</p>
            </Content>
        )
    }
}