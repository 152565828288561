import React from 'react';
import customAxios from '../../configs/customAxios.js'
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import '../../css/CollectionManagement.css';
import { Link } from 'react-router-dom';
import Content from '../templates/Content.js';
import FadeIn from 'react-fade-in';
import { Table, Space } from 'antd';

export default class CollectionManagementContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collections: null
        };
    };

    componentDidMount() {
        customAxios.get(`/collections`)
            .then(response => {
                this.setState({
                    collections: response.data
                });
            })
            .catch( error => {
                console.log(error);
            });
    };

    render() {
        if (!this.state.collections) {
            return <MyLoadingScreen text={'Fetching collection data...'}/>
        }

        else if (this.state.collections.length === 0) {
            return (
                <Content heading={'Manage collections'}>
                    <p> There are no illustrations yet. </p>
                    <Link to={{pathname: `/admin/collections/new`}}
                                className='link'
                                id='addCollection' >
                                Add a new collection
                    </Link>
                </Content>
            )
        }

        return (
            <Content heading={`Manage collections`}>

                <CollectionManagement collections={this.state.collections} />
                <Link to={{pathname: `/admin/collections/new`}}
                                className='link'
                                id='addCollection' >
                                Add a new collection
                </Link>
            </Content>
        )
    }
};

class CollectionManagement extends React.Component {

    render() {

        const columns = [
            { 
                title: 'Name',
                dataIndex: 'name',
                key: '_id',
                render: text => <p className='collectionName'> {text} </p>

            },

            {   title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">

                        <Link to={{pathname: `/admin/collections/${record._id}/update`}}
                            className='link' >
                            Update collection
                        </Link>

                        <Link to={{pathname: `/admin/collections/${record._id}/products`}}
                                className='link' >
                            Manage products
                        </Link>

                        <Link to={{pathname: `/admin/collections/${record._id}/products/new`}}
                                className='link' >
                            Add a product
                        </Link>

                        <Link to={{pathname: `/collections/${record._id}/products/`}}
                                className='link' >
                            View
                        </Link>
                    </Space>
                )
            },

        ]
        return (
            <Content>
                <FadeIn>
                    <Table dataSource={this.props.collections}
                        columns={columns}
                        pagination={false}/>
                </FadeIn>
            </Content>
        )
    }
}



