import React from 'react';
import customAxios from '../../configs/customAxios.js'
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import { Link } from 'react-router-dom';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactHtmlParser from 'react-html-parser';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {ReactComponent as BlogLogo} from '../../lamaith-logo.svg';
import '../../css/Diary.css';
import FadeIn from 'react-fade-in';
import { Container, Row, Col } from 'react-bootstrap';

export default class MyDiaryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entries: null,
            skip: 0,
            limit: 10,
            hasMore: true,
        };
        this.fetchMore = this.fetchMore.bind(this);
    };

    componentDidMount() {
        customAxios.get(`/diary?skip=${this.state.skip}&limit=${this.state.limit}`)
            .then(response => {
                const skip = this.state.skip + this.state.limit;
                this.setState({
                    entries: response.data,
                    skip: skip,
                });
                if (this.state.entries.length < this.state.limit) {
                    this.setState({
                        hasMore: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    fetchMore() {
        customAxios.get(`/diary?skip=${this.state.skip}&limit=${this.state.limit}`)
            .then(response => {
                const skip = this.state.skip + this.state.limit;
                if (response.data.length <= 0) {
                    //console.log('no more entries');
                    this.setState({
                        hasMore: false
                    });
                }

                else {
                    //console.log('has more = true');
                    this.setState(prevState => ({
                        entries: [...prevState.entries, ...response.data],
                        skip: skip,
                        hasMore: true
                    }));
                    //console.log('entries: ');
                    //console.log(this.state.entries);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        if (!this.state.entries) {
            return <MyLoadingScreen text={'Fetching diary data...'}/>
        }
        return <MyDiary entries={this.state.entries}
                        fetchMore={this.fetchMore}
                        hasMore={this.state.hasMore}/>
    }
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class MyDiary extends React.Component {
    render() {
        const entries = this.props.entries.map(entry => (
            <EntryBrief entry={entry} key={entry._id} />
        ));

        return (
            <FadeIn>
                <div className='diary'>
                    <div className='logoContainer'> 
                        <BlogLogo className='logo'/>
                    </div>

                    <InfiniteScroll
                        dataLength = {this.props.entries.length}
                        next = {this.props.fetchMore}
                        hasMore = {this.props.hasMore}
                        scrollThreshold = {0.9}
                        loader = {
                            <p> Fetching more entries... 
                                <span> <Spin indicator={loadingIcon} /> </span>
                            </p>
                        }
                    >
                        {entries}
                    </InfiniteScroll>
                </div>
            </FadeIn>
        )
    }
}

class Entry extends React.Component {
    render() {
        return (
            <div className='entry'>
                <p className='entryTitle'>
                    {this.props.entry.name}
                </p>

                <p className='entryDate'>
                    {moment(this.props.entry.createdAt)
                        .format('MMMM Do YYYY, h:mm a')}
                </p>

                {ReactHtmlParser(this.props.entry.content)}
            </div>
        );
    }
}

class EntryBrief extends React.Component {
    render() {
        return (
            <Container className='entryBrief'>
                <div className='entryBriefInner'>
                    <Col className='entryPhotoCol'>
                        <div className='entryPhotoDiv'>
                            <img src={this.props.entry.image_src}
                                alt={this.props.entry.name}
                                className='entryPhoto'
                            />
                        </div>
                    </Col>

                    <Col className='entryDescriptionCol'>
                        <Row className='entryDescriptionRow'>
                            <p className='entryDescription'> {this.props.entry.description} </p>
                        </Row>

                        <Row className='seeMoreAndDateRow'>
                            <Col>
                                <Link to={`/diary/${this.props.entry._id}`} className='seeMoreText'> See more... </Link>
                            </Col>
                            
                            <Col>
                                <p className='entryDate'>
                                    {moment(this.props.entry.createdAt)
                                        .format('MMMM Do YYYY, h:mm a')}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Container>
        );
    }
}