import React from 'react';
import customAxios from '../../configs/customAxios.js'
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import Content from '../templates/Content.js';
import FadeIn from 'react-fade-in';
import 'react-slideshow-image/dist/styles.css'
import '../../css/Collection.css';
import { Container, Row, Col } from 'react-bootstrap';

export default class CollectionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            products: null,
            description_pic_src: '',
        }
    }

    componentDidMount() {
        customAxios.get(`/collections/${this.props.match.params.collectionId}/products`)
        .then(response => {
            this.setState({
                name: response.data.name,
                products: response.data.products,
                description_pic_src: response.data.description_pic_src,
            });
        })
        .catch(error => {
            return (error);
        })
    }

    render() {

        if (!this.state.products) {
            return (
                <MyLoadingScreen text='Fetching collection data...'/>
            );
        }

        else {
            return (
                <Collection name={this.state.name}
                    products={this.state.products}
                    description_pic_src={this.state.description_pic_src}
                />
            );
        }
    }
}

class Collection extends React.Component {

    render() {

        if (this.props.products.length === 0) {
            return (
                <Content heading={this.props.name}>
                    <p> This collection is currently empty. </p>
                </Content>
            )
        }

        else {
            
            // Combine all products' photos into a single array.
            var photos = [];
            var i, j;
            for (i = 0; i < this.props.products.length; i++) {
                for (j = 0; j < this.props.products[i].photos.length; j++) {
                    photos.push(this.props.products[i].photos[j]);
                }
            }
            return (
                <Content heading={`${this.props.name}`}>
                    <Lookbook
                        photos={photos}
                        description_pic_src={this.props.description_pic_src}
                    />
                </Content>
            );
        }
    }
}

class Lookbook extends React.Component {
    render() {
        const photos = this.props.photos;

        const photos9plus = this.props.photos.slice(9,).map((photo, index) => 
        
            <PhotoBrief photo={photo} index={index+9} className='photo9+div'/>
        );

        return (
            <FadeIn className='photoGrid'>
                <Container fluid>
                    {/* Details and P0. */}
                    <Row className='photoRow1'>
                        <Col>
                            <PhotoBrief photo={photos[0]} index={0} />
                        </Col>
                        <Col>
                            <div id='descriptionDiv'>
                                <img src={this.props.description_pic_src}
                                     id='descriptionPic'/>
                            </div>
                        </Col>
                    </Row>
                        
                    {/* P1, P2. */}
                    <Row className='photoRow2'>
                        <Col>
                            <PhotoBrief photo={photos[2]} index={2} />
                        </Col>

                        <Col>
                            <PhotoBrief photo={photos[1]} index={1} />
                        </Col>
                    </Row>

                    {/* P3, P4. */}
                    <Row className='photoRow3'>
                        <Col>
                            <PhotoBrief photo={photos[4]} index={4} />
                        </Col>

                        <Col>
                            <PhotoBrief photo={photos[3]} index={3} />
                        </Col>
                    </Row>

                    {/* P5, P6. */}
                    <Row className='photoRow4'>
                        <Col>
                            <PhotoBrief photo={photos[6]} index={6} />
                        </Col>

                        <Col>
                            <PhotoBrief photo={photos[5]} index={5} />
                        </Col>
                    </Row>

                    {/* P7, P8. */}
                    <Row className='photoRow5'>
                        <Col>
                            <PhotoBrief photo={photos[8]} index={8} />
                        </Col>

                        <Col>
                            <PhotoBrief photo={photos[7]} index={7} />
                        </Col>
                    </Row>

                    {/* P9+. */}
                    <Row className='photoRow6'>
                        {photos9plus}
                    </Row>
                </Container>
            </FadeIn>
        );
    }
}
class PhotoBrief extends React.Component {
    render() {
        return this.props.photo != null && this.props.photo != '' ? (
            <a href={this.props.photo} target="_blank" rel="noopener noreferrer">
                <div className='photoDiv' id={`photo${this.props.index}div`}>
                    <img 
                        src={this.props.photo}
                        alt=''
                        id={`photo${this.props.index}`}
                        className='photo'
                    />
                </div>
            </a>
        ) : (<></>);
    }
}