import React from 'react';
import { PhoneFilled, MailFilled, HomeFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
    instagramURL,
    facebookURL,
    youtubeURL,
    linkedInURL,
    } from '../../configs/ExternalUrls.js';

library.add(fab);

export default class ContactDetails extends React.Component {
    render() {
        return (
            <p> <span> <HomeFilled /> LAMAITH </span> 
            <br/> <span> <MailFilled /> General inquiries: lamaith.official@gmail.com </span>
            <br/> <span> <MailFilled /> Customer care: lamaithsayshi@gmail.com </span>
            <br/> <span> <PhoneFilled /> (+61) 451252910 </span>
            <br/>
            {/* <br/> <span> <FontAwesomeIcon icon="fa-brands fa-facebook" /> <a href={facebookURL} target="_blank" rel="noopener noreferrer"> {facebookURL} </a> </span>
            <br/> <span> <FontAwesomeIcon icon="fa-brands fa-youtube" /> <a href={youtubeURL} target="_blank" rel="noopener noreferrer"> {youtubeURL} </a> </span>
            <br/> <span> <FontAwesomeIcon icon="fa-brands fa-instagram" /> <a href={instagramURL} target="_blank" rel="noopener noreferrer"> {instagramURL} </a> </span>
            <br/> <span> <FontAwesomeIcon icon="fa-brands fa-linkedin" /> <a href={linkedInURL} target="_blank" rel="noopener noreferrer"> {linkedInURL} </a> </span> */}
            </p>
        )
    }
}