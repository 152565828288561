import React from 'react';
import Content from "../templates/Content.js";
import {withRouter} from 'react-router-dom';
import '../../css/ServerResponse.css';

class ServerResponse extends React.Component {

    render() {
        return (
            <Content heading = {this.props.heading}>
                {this.props.message}

                <button className='home' 
                    onClick={() => {this.props.history.push('/')}}>
                    Return to homepage
                </button>

                <button className='back'
                    onClick={() => {this.props.history.goBack()}}>
                    Return to previous
                </button>

            </Content>
        )
    }
}

export default withRouter(ServerResponse);
