import React from 'react';
//import ReactDOM from 'react-dom';
import { Formik, Form, useField, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import customAxios from '../../configs/customAxios.js';
import Content from '../templates/Content.js';
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import '../../css/Form.css';
import FadeIn from 'react-fade-in';

export default class UpdateProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            product: null
        }
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.deleteSelf = this.deleteSelf.bind(this);
    }

    componentDidMount() {
        customAxios.get(`/collections/${this.props.match.params.collectionId}/products/${this.props.match.params.productId}`)
        .then(response => {
            this.setState({
                product: response.data
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

    deleteSelf() {
            customAxios.delete(
                `/collections/${this.props.match.params.collectionId}/products/${this.props.match.params.productId}`)
            .then((response) => {
                this.props.history.goBack();
            }, 
            (error) => {
                console.log(error);
                this.props.history.push('/request_failure');
            })
        console.log('OK');
    }
    
    showDeleteConfirm() {
        const deleteSelf = this.deleteSelf;
        Modal.confirm({
            title: 'Confirm deletion',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone',
            onOk() {
                deleteSelf();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {

        if (!this.state.product) {
            return (
                <MyLoadingScreen text={'Fetching product data...'} />
            )
        }

        else {
            return (
                <Content heading={`Update ${this.state.product.name}`}>
                    {ProductUpdateForm(
                        this.props.match.params.collectionId,
                        this.props.match.params.productId,
                        this.state.product,
                        this.props.history.push)}
                    <Button onClick={this.showDeleteConfirm}>
                        Delete this product
                    </Button>
                </Content>
            );
        }
    }
}

// Validation schema.
const schema = Yup.object().shape({
    name: Yup.string()
        .required('Please enter a name for this product.')
});

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className='MyTextInputContainer'>  
            <p className='label'> 
                <label htmlFor={props.id || props.name}>{label} </label>
            </p>
            <input className='text-input' 
                   {...field} {...props} />
            {meta.touched && meta.error ?
            ( <div className='error'> {meta.error} </div> ) : null}
        </div>
  );
}

const ProductUpdateForm = (collectionId, productId, initialValues, historyPush) => {

    // Submission logic.
    const handleSubmit = function (values, actions) {
        const updatedProduct = {
            name: values.name,
            photos: values.photos,
        }
        customAxios.put(`/collections/${collectionId}/products/${productId}/update`, updatedProduct)
            .then(
                (response) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush('/request_success');
                }, 
                (error) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    historyPush('/request_failure');
                    console.log(error);
                }
            )
    }

    return (
        
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                {props => (
                    <Form>

                        <MyTextInput
                            label='Product name'
                            name='name'
                            type='text'
                            placeholder='Enter a name for this product.'
                        />
                
                        <label> Photos </label>
                        <FieldArray name="photos"  
                            render={arrayHelpers => (
                                <div>
                                    {props.values.photos && props.values.photos.length > 0 ? (
                                        props.values.photos.map((photo, index) => (
                                            <FadeIn>
                                                <div key={index}>
                                                    <Field name={`photos.${index}`} 
                                                        className='photoField'
                                                    />
                                                    {photo && 
                                                        <div>
                                                            <img className='thumbnail' 
                                                                src={photo}
                                                                alt=''
                                                            />
                                                            <br/>
                                                        </div>
                                                    }
                                                    <button
                                                        type='button'
                                                        className='removeButton'
                                                        onClick={() => arrayHelpers.remove(index)} // Remove a photo from the list
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </FadeIn>
                                        ))) : null 
                                    }
                                    <button type='button'
                                        className='addButton'
                                        onClick={() => arrayHelpers.push('')}>
                                        Add a photo
                                    </button>
                                </div>
                            )}
                        />
                        <button type='submit'
                            className='submitButton'
                        >
                            Submit
                        </button>

                    </Form>
                )}
            </Formik>
        </div>
    );
};
