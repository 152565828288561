import React from 'react';
import customAxios from '../../configs/customAxios.js'
import MyLoadingScreen from '../presentationals/MyLoadingScreen.js';
import { Link } from 'react-router-dom';
import Content from '../templates/Content.js';
import FadeIn from 'react-fade-in';
import { Table, Space } from 'antd';

export default class DiaryManagementContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entries: null
        };
    };

    componentDidMount() {
        customAxios.get(`/diary`)
            .then(response => {
                this.setState({
                    entries: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        if (!this.state.entries) {
            return <MyLoadingScreen text={'Fetching diary data...'}/>
        }

        else if (this.state.entries.length === 0) {
            return (
                <Content heading={'Diary entries'}>
                    <p> There are no entries yet. </p>
                    <Link to={{pathname: `/admin/diary/new`}}
                        className='link'
                        id='addEntry' >
                        Add an entry
                    </Link>
                </Content>
            )
        }
        else {
            return (
                <Content heading={`Diary entries`}>
                    <DiaryManagement 
                        entries={this.state.entries}
                    />
                    <Link to={{pathname: `/admin/diary/new`}}
                        className='link'
                        id='addEntry' >
                        Add an entry
                    </Link>
                </Content>
            )
        }
    }
};

class DiaryManagement extends React.Component {

    render() {

        const columns = [
            { 
                title: 'Name',
                dataIndex: 'name',
                key: '_id',
                render: text => <p className='entryName'> {text} </p>

            },

            {   title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle" key={record._id}>

                        <Link to={{pathname: `/admin/diary/${record._id}/update`}}
                            className='link' >
                            Update entry
                        </Link>

                    </Space>
                )
            },

        ]
        return (
            <Content>
                <FadeIn>
                    <Table dataSource={this.props.entries}
                        columns={columns}
                        pagination={false}/>
                </FadeIn>
            </Content>
        )
    }
}



